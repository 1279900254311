import React from 'react'
import Meta from '../components/Meta'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import PageHeading from '../components/PageHeading'
import Header from '../components/Header'
import Footer from '../components/Footer'
import RichText from '../components/RichText'

class IndexRoute extends React.Component {
    render() {
        const routeData = this.props.data
        const metaDataElements = routeData.kontentItemSiteMetadata.elements
        const postfix = metaDataElements.title.value

        const homepageElements = routeData.kontentItemHomepage.elements

        const pageTitle = homepageElements.title.value
        const pageSubtitle = homepageElements.subtitle.value
        const metaDescription = homepageElements.meta_description.value
        const description = homepageElements.description

        return (
            <Layout>
                <div>
                    <Meta title={pageTitle} postfix={postfix} description={metaDescription} />
                    <Header isHomePage />
                    <PageHeading data={pageSubtitle} isHomePage />
                    <section className="about-me">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-10">
                                    <RichText data={description} />
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer />
                </div>
            </Layout>
        )
    }
}

export default IndexRoute

export const pageQuery = graphql`
  query IndexQuery {
    kontentItemSiteMetadata(system: {codename: {eq: "site_metadata"}}) {
      elements {
        title {
          value
        }
      }
    }
    kontentItemHomepage(system: {codename: {eq: "uvodni_stranka"}}) {
      elements {
        meta_description {
          value
        }
        title {
          value
        }
        subtitle {
          value
        }
        description {
          value
          links {
            link_id
            url_slug
            type
          }
          images {
            image_id
            description
            url
            fluid(maxWidth: 700) {
              ...KontentAssetFluid
            }
          }
          modular_content {
            ... on kontent_item_component_images_wrapper {
              id
              elements {
                description {
                  value
                }
                photos {
                  value {
                    fluid(maxWidth: 350) {
                      aspectRatio
                      ...KontentAssetFluid
                    }
                    fixed(height: 700) {
                      aspectRatio
                      src
                      ...KontentAssetFixed
                    }
                    description
                    url
                  }
                }
              }
              system {
                codename
                type
              }
            }
            ... on kontent_item_component_text_and_image {
              id
              elements {
                text {
                  value
                }
                image {
                  value {
                    fluid(maxWidth: 350) {
                      aspectRatio
                      ...KontentAssetFluid
                    }
                    fixed(height: 700) {
                        aspectRatio
                        src
                        ...KontentAssetFixed
                    }
                    description
                    url
                  }
                }
              }
              system {
                codename
                type
              }
            }
          }           
        }
      } 
    }  
 }
`
